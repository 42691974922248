<template>
  <el-dialog v-loading="loading" :title="onlyLook ? '查看详情' : title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" @close="close">
    <el-card>
      <el-form :model="form" ref="form" :rules="rules" label-width="80px">
        <el-col :span="12">
          <el-form-item label="上级机构" prop="upperComId">
            <el-select :disabled="onlyLook" clearable v-model="form.upperComId" placeholder="请选择上级机构">
              <el-option label="无" :value="0"></el-option>
              <el-option v-for="(item, index) in companyList" :key="index" :label="item.companyName" :value="item.companyId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构名称" prop="companyName">
            <el-input :disabled="onlyLook" maxlength="20" placeholder="请输入机构名称" show-word-limit v-model="form.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构类型" prop="companyType">
            <el-select :disabled="onlyLook" v-model="form.companyType" placeholder="请选择机构类型">
              <el-option v-for="(item, index) in companyTypeList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否有效" prop="companyStatus">
            <el-select :disabled="onlyLook" v-model="form.companyStatus" placeholder="请选择当前机构是否有效">
              <el-option label="正常" value="1"></el-option>
              <el-option label="暂停" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户名称" prop="accountName">
            <el-input :disabled="onlyLook" v-model="form.accountName" placeholder="请输入开户名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户银行" prop="accountBank">
            <el-input :disabled="onlyLook" v-model="form.accountBank" placeholder="请输入开户银行"></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-col :span="24">
            <el-form-item label="银行账号" prop="bankCode">
              <el-input :disabled="onlyLook" v-model="form.bankCode" placeholder="请输入银行账号"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col>
          <el-form-item label="地区" prop="diqu">
            <el-col :span="8">
              <el-form-item label="省" label-width="40px" prop="provinceInfo">
                <el-select :disabled="onlyLook" v-model="form.provinceInfo" placeholder="请选择机构所在省" @change="getcityinfo1">
                <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.adcode">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="市" label-width="40px" prop="cityInfo">
                <el-select :disabled="onlyLook" v-model="form.cityInfo" placeholder="请选择机构所在市" @change="getareainfo1">
                  <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.adcode">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item  label="区" label-width="40px" prop="areaInfo">
                <el-select :disabled="onlyLook" v-model="form.areaInfo" placeholder="请选择机构所在区县" @change="getcounty1">
              <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.adcode">
              </el-option>
            </el-select>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="地址" prop="address">
          <el-input :disabled="onlyLook" maxlength="200" placeholder="请输入详细地址" show-word-limit v-model="form.address"></el-input>
        </el-form-item>
        </el-col>
      </el-form>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitCommit('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveOrganization , getAllInstitutionsInfo, postRequest } from 'src/api/index'
export default {
  data() {
    return {
      form: {
        id: '',
        companyName: '',
        companyType: '',
        address: '',
        companyStatus: '',
        accountName: '',
        accountBank: '',
        bankCode: '',
        provinceInfo: '',
        cityInfo: '',
        areaInfo: ''
      },
      rules:{
        upperComId: [
          {required: false, message: '请选择上级机构', trigger: 'change, blur'}
        ],
        companyName: [
          {required: true, message: '请输入机构名称', trigger: 'blur'}
        ],
        companyType: [
          {required: true, message: '请选择机构类型', trigger: 'change'}
        ],
        companyStatus: [
          {required: true, message: '请选择当前机构是否有效', trigger: 'change'}
        ],
        accountName: [
          {required: false, message: '请输入开户名称', trigger: 'blur'}
        ],
        accountBank: [
          {required: false, message: '请输入开户银行', trigger: 'blur'}
        ],
        bankCode: [
          {required: false, message: '请输入银行账号', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请输入地址', trigger: 'blur'}
        ],
        provinceInfo: [
          {required: true, message: '请选择当前机构所在省', trigger: 'change'}
        ],
        cityInfo: [
          {required: true, message: '请选择当前机构所在市', trigger: 'change'}
        ],
        areaInfo: [
          {required: true, message: '请选择当前机构所在区县', trigger: 'change'}
        ],
      },
      model: {
        province: '',
        city: '',
        area: ''
      },
      dialogFormVisible: true,
      title: '新增机构',
      polygons: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      district: null,
      companyList: [],
      companyTypeList: [],
      loading: true
    }
  },
  props: ['info', 'onlyLook'],
  mounted(){
    if (this.info != '') {
      console.log(this.info, 'info')
      this.form = this.info
      this.title = '修改机构'
      this.getcityinfo(this.info.provinceInfo)
      this.getareainfo(this.info.cityInfo)
    }
    this.getprovinceinfo()
    postRequest('/company/queryCompany', {}).then(res=>{
      console.log(res, 'jigou')
      this.companyList = res
    })
    postRequest('/dict/getDictByCategory', {dictionaryCategoryCode: '09'}).then(res=>{
      console.log(res, '机构类型')
      this.companyTypeList = res
    })
  },
  methods: {
    close() {
      this.$emit('closecloseAddNew')
    },
    submitCommit (form) {
      console.log(this.form)
      if (this.info != '') {
        this.$refs[form].validate((valid) => {
          if (valid) {
            console.log(this.form, 'this.form')
            postRequest('/company/updateCompany', this.form).then(res=> {
              console.log(res, 'res')
              this.$message({
                message: '修改成功',
                type:'success'
              })
              this.close()
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$refs[form].validate((valid) => {
          if (valid) {
            console.log(this.form, 'this.form')
            saveOrganization(this.form).then(res=> {
              console.log(res, 'res')
              this.$message({
                message: '添加成功',
                type:'success'
              })
              this.close()
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
      
    },
    // 获取省份列表
      getprovinceinfo() {
        console.log('mounted 省')
        var that = this;
        //加载行政区划插件
        if (!that.district) {
          //实例化DistrictSearch
          var opts = {
            subdistrict: 1, //获取边界不需要返回下级行政区
            extensions: "all", //返回行政区边界坐标组等具体信息
            level: "country", //查询行政级别为 province省级 city市  district区县
          };
          that.district = new AMap.DistrictSearch(opts);
        }
        //行政区查询
        let code = "中国";
        that.district.search(code, function(status, result) {
          console.log(result, 'result')
          let list = []
          that.provinceList = result.districtList[0].districtList
          that.loading = false
        });
      },
      // 获取城市列表
      getcityinfo(province) {
        console.log('打开执行？')
        this.provinceList.map(item => {
          if (item.adcode == province) {
            this.form.provinceName = item.name
          }
        })
        console.log(province, 'province')
        var that = this;
        that.cityList = []
        that.areaList = []
        that.district = null
        //加载行政区划插件
        if (!that.district) {
          //实例化DistrictSearch
          var opts = {
            subdistrict: 1, //获取边界不需要返回下级行政区
            extensions: "all", //返回行政区边界坐标组等具体信息
            level: "province", //查询行政级别为 province省级 city市  district区县
          };
          that.district = new AMap.DistrictSearch(opts);
        }
        //行政区查询
        let code = province;
        that.district.search(code, function(status, result) {
          console.log(result)
          let list = []
          that.cityList = result.districtList[0].districtList
        });
      },
      // 获取区县列表
      getareainfo(city) {
        console.log(city)
        this.cityList.map(item => {
          if (item.adcode == city) {
            this.form.cityName = item.name
          }
        })
        var that = this;
        that.district = null
        //加载行政区划插件
        if (!that.district) {
          //实例化DistrictSearch
          var opts = {
            subdistrict: 1, //获取边界不需要返回下级行政区
            extensions: "all", //返回行政区边界坐标组等具体信息
            level: "city", //查询行政级别为 province省级 city市  district区县
          };
          that.district = new AMap.DistrictSearch(opts);
        }
        //行政区查询
        let code = city;
        that.district.search(code, function(status, result) {
          console.log(result)
          let list = []
          that.areaList = result.districtList[0].districtList
        });
      },
      getcounty(val){
        this.areaList.map(item => {
          if (item.adcode == val) {
            this.form.areaName = item.name
          }
        })
      },




      // 获取城市列表
      getcityinfo1(province) {
        console.log('打开执行？')
        this.form.cityInfo = ''
        this.form.areaInfo = ''
        this.provinceList.map(item => {
          if (item.adcode == province) {
            this.form.provinceName = item.name
          }
        })
        console.log(province, 'province')
        var that = this;
        that.cityList = []
        that.areaList = []
        that.district = null
        //加载行政区划插件
        if (!that.district) {
          //实例化DistrictSearch
          var opts = {
            subdistrict: 1, //获取边界不需要返回下级行政区
            extensions: "all", //返回行政区边界坐标组等具体信息
            level: "province", //查询行政级别为 province省级 city市  district区县
          };
          that.district = new AMap.DistrictSearch(opts);
        }
        //行政区查询
        let code = province;
        that.district.search(code, function(status, result) {
          console.log(result)
          let list = []
          that.cityList = result.districtList[0].districtList
        });

      },
      // 获取区县列表
      getareainfo1(city) {
        this.form.areaInfo = ''
        console.log(city)
        this.cityList.map(item => {
          if (item.adcode == city) {
            this.form.cityName = item.name
          }
        })
        var that = this;
        that.district = null
        //加载行政区划插件
        if (!that.district) {
          //实例化DistrictSearch
          var opts = {
            subdistrict: 1, //获取边界不需要返回下级行政区
            extensions: "all", //返回行政区边界坐标组等具体信息
            level: "city", //查询行政级别为 province省级 city市  district区县
          };
          that.district = new AMap.DistrictSearch(opts);
        }
        //行政区查询
        let code = city;
        that.district.search(code, function(status, result) {
          console.log(result)
          let list = []
          that.areaList = result.districtList[0].districtList
        });
      },
      getcounty1(val){
        this.areaList.map(item => {
          if (item.adcode == val) {
            this.form.areaName = item.name
          }
        })
      }
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.el-card {
  margin-top: -20px;
}
</style>
