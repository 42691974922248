<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="机构名称">
              <el-input clearable v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" :disabled="disabledSearch" @click="searchInstitutions">查询</el-button>
            </el-form-item>
            <!-- <el-form-item>
              <el-button @click="addNew('')" type="primary" plain icon="el-icon-plus">新增</el-button>
            </el-form-item> -->
            <el-form-item>
              <el-button  @click="updateNew" plain icon="el-icon-setting" type="primary">修改</el-button>
            </el-form-item>
            <!-- <el-form-item>
              <el-button type="danger" plain icon="el-icon-delete" @click="handleDelete">删除</el-button>
            </el-form-item> -->
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button @click="addNew('')" type="primary" plain icon="el-icon-plus">新增</el-button>
        <!-- <el-button type="danger" plain icon="el-icon-delete" @click="handleDelete">删除</el-button> -->
      </div>
      <div class="table-block">
        <div v-show="multipleSelection.length>0" class="Selectnum">已选中<span style="color:#5479FF">{{multipleSelection.length}}</span>条信息</div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="{'text-align': 'center', 'color': '#999', 'background':'#EEF1FC'}"
          :cell-style="{'text-align':'center'}"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          @row-click="handleClickTableRow"
          @selection-change="handleSelectionChange"
        >
          <el-table-column fixed type="selection" width="55"> </el-table-column>
          <!-- <el-table-column type="index" label="序号" width="50"> </el-table-column> -->
          <el-table-column prop="companyName" label="机构"> </el-table-column>
          <el-table-column prop="companyTypeName" label="机构类型"> </el-table-column>
          <el-table-column prop="address" label="地区"> </el-table-column>
          <el-table-column prop="companyStatus" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.companyStatus"
                active-color="#5479FF"
                inactive-color="#D5D5D5"
                active-value="1"
                @change="changeStatus(scope.row)"
                inactive-value="0">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="设置时间"> </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="200">
            <template slot-scope="scope">
              <el-button @click="lookFor(scope.row)" type="text" size="small">查看</el-button>
              <el-button @click="updateNewAnthor(scope.row)" type="text" size="small">修改</el-button>
              <!-- <el-button type="text" v-show="scope.row.companyStatus == 1" @click="changeStatus(scope.row)" size="small">禁用</el-button>
              <el-button type="text" v-show="scope.row.companyStatus == 0" @click="changeStatus(scope.row)" size="small">启用</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <editInstitutions :onlyLook="onlyLook" :info="info" v-if="showEditInstitutions" @closecloseAddNew="closecloseAddNew"></editInstitutions>
  </div>
</template>

<script>
import { searchInstitutionsInfo, deleteOrganization, postRequest, saveOrganization } from 'src/api/index'
import editInstitutions from '@/views/basisSet/component/editInstitutions'
export default {
  data() {
    return {
      form: {
        name: '',
      },
      tableData: [],
      multipleSelection: [],
      showEditInstitutions: false,
      state: '',
      info: '',
      onlyLook: '',
      total: null,
      pageNo: 1,
      pageSize: 10,
      disabledSearch: false,
      loading: true
    }
  },
  mounted(){
    this.getAllinfo()
  },
  components: { editInstitutions },
  methods: {
    closecloseAddNew() {
      this.showEditInstitutions = false
      this.getAllinfo()
    },
    changeStatus(val){
      console.log(val)
      let data = {
        companyId: val.companyId,
        companyStatus: val.companyStatus
      }
      postRequest('/company/updateCompany', data).then(res=>{
        console.log(res)
        this.$message({
          message: '状态修改成功',
          type: 'success'
        })
        // this.getAllinfo()
      })
    },
    addNew(val) {
      console.log(val, 'val')
      // if (val == '') {
      //   this.nolyLook = false
      // } else {
      //   this.nolyLook = false
      // }
      this.onlyLook = false
      this.info = val
      this.showEditInstitutions = true
    },
    lookFor (val) {
      console.log(val)
      this.info = val
      this.showEditInstitutions = true
      this.onlyLook=true
    },
    updateNew(){
      if (this.multipleSelection.length > 1) {
        this.$message({
          message: '只能选择一条数据修改',
          type:'warning'
        })
      } else if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择一条数据修改',
          type:'warning'
        })
      } else if (this.multipleSelection.length == 1) {
        this.info = this.multipleSelection[0]
        this.showEditInstitutions = true
        this.onlyLook = false
      }
    },
    updateNewAnthor(val){
      this.info = val
      this.showEditInstitutions = true
      this.onlyLook = false
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    handleClick(row) {
      console.log(row)
    },
    handleClickTableRow(row){
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //  按名称查询
    searchInstitutions () {
      this.getAllinfo(1)
    },
    // 查询所有机构
    getAllinfo (no) {
      this.loading = true
      this.disabledSearch = true
      let data = {
        pageNo: no ? no :this.pageNo,
        pageSize: this.pageSize,
        companyName: this.form.name ? this.form.name : ''
      }
      searchInstitutionsInfo(data).then(res=>{
        console.log(res, 'request123')
        this.total = res.count
        this.disabledSearch = false
        this.loading = false
        this.tableData = res.data
      }).catch(()=>{
        this.disabledSearch = false
        this.loading = false
      })
    },
    // 删除机构
    handleDelete(){
      if (this.multipleSelection.length <= 0) {
        this.$message('请选择删除项')
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          // this.multipleSelection.map(item => {
          //   deleteOrganization({companyId: item.companyId}).then(res=> {
          //     console.log(res)
          //     if (res == null) {
          //       this.$message({
          //         message: '删除成功',
          //         type: 'success'
          //       })
          //       this.getAllinfo()
          //     }
          //   })
          // })
          deleteOrganization(this.multipleSelection).then(res=> {
              console.log(res)
              if (res == null) {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
                this.getAllinfo()
              }
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    // changeStatus (line) {
    //   let data = {
    //     companyId: line.companyId
    //   }
    //   if (line.companyStatus == '1') {
    //     data.companyStatus = '0'
    //   } else {
    //     data.companyStatus = '1'
    //   }
    //   console.log(data, 'data')
    //   postRequest('/company/updateCompany', data).then(res=>{
    //     console.log(res)
    //     this.$message({
    //       message: '状态修改成功',
    //       type: 'success'
    //     })
    //     this.getAllinfo()
    //   })
    // },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllinfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllinfo()
    }
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
